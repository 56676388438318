<template>
  <div class="ci-ui-input">
    <DatePicker
      v-if="!isButton"
      v-model="_value"
      :locale="{
        id: $i18n.locale,
        masks: { weekdays: 'WWW' }
      }"
      mode="date"
      mask="YYYY-MM-DD"
      :is-range="isRange"
      :is-expanded="$store.state.window.to768"
      :max-date="new Date()"
      :min-date="isMinDate ? _userMinDate : null"
    />

    <DatePicker
      v-else
      v-model="value"
      :locale="{
        id: $i18n.locale,
        masks: { weekdays: 'WWW' }
      }"
      mode="date"
      :is-range="isRange"
      :is-expanded="$store.state.window.to768"
      :max-date="new Date()"
      :min-date="_userMinDate"
      @input="edit($event)"
    />

    <!-- @dayclick="click($event)" -->

    <div class="buttons">
      <BlueButton
        v-if="isButton"
        style="margin-top: 20px;"
        :text="$t('components.ui.inputs.date.button')"
        @click="change()"
      />

      <button
        v-if="original"
        class="delete"
        @click="$emit('setDate', null), value = null"
      >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.77117 1.7718L16.2276 16.2282M1.77117 16.2282L16.2276 1.7718" stroke="#DA2828" stroke-width="3" stroke-linecap="round"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'
import BlueButton from '@/ui/buttons/BlueButton.vue'

import 'v-calendar/dist/style.css'

export default {
  components: {
    DatePicker,
    BlueButton
  },
  props: {
    modelValue: {
      type: [String, Object, Array],
      default: null
    },
    original: {
      type: Object,
      default: null
    },
    isRange: {
      type: Boolean,
      default: true
    },
    isButton: {
      type: Boolean,
      default: true
    },
    isGraph: {
      type: Boolean,
      default: false
    },
    isMinDate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: null
    }
  },
  mounted () {
    if (this.original) {
      this.value = this.original
    } else {
      this.value = null
    }
  },
  computed: {
    _userMinDate () {
      const user = this.$store.state.user.data.email_verified_at
      const date = user ? new Date(user) : false

      return date
    },
    _value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    original () {
      if (this.original) {
        this.value = this.original
      } else {
        this.value = null
      }
    }
  },
  methods: {
    change () {
      if (this.isGraph) {
        const val = this.value
        const isValidMin = ((Date.parse(val.end) - Date.parse(val.start)) / (1000 * 60 * 60 * 24 * 2)) < 0.5
        // const max = this.$store.state.window.to768 ? 6 : 12
        // const isValidMax = ((Date.parse(val.end) - Date.parse(val.start)) / (1000 * 60 * 60 * 24 * 2)) > max
        if (isValidMin) {
          this.value.end = new Date(val.end.setDate(val.start.getDate() + 1))
        }

        // if (isValidMax) {
        //   this.value.end = new Date(val.end.setDate(val.start.getDate() + 1))
        // }

        this.$emit('setDate', this.value)
      } else {
        this.$emit('setDate', this.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ci-ui-input {
  margin-top: 6px;
  // width: 100%;
  background: white;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
  padding: 10px;
  border-radius: 15px;

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;

    button {
      margin-top: 0 !important;
    }

    button.delete {
      height: 46px;
      width: 46px;
      min-width: 46px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(218, 40, 40, .2);
      border-radius: 50%;
    }
  }
}

@media screen and (max-width: 768px) {
  .ci-ui-input {
    width: calc(100vw - 34px);
  }
}
</style>

<style lang="scss">
.vc-container {
  // width: 100%;
  border: none;
}

.vc-header {
  margin-bottom: 10px;
  padding: 12px 0 0 0;

  .vc-title {
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: rgb(var(--color-dark));
    font-family: Medium;
  }
}

.vc-arrows-container {
  padding: 0;

  .vc-arrow {
    height: 36px;
    width: 36px;
    margin: 0;
    border-radius: 50%;

    .vc-svg-icon {
      margin-bottom: 2px;
    }
  }
}

.vc-weeks {
  padding: 0;

  .vc-weekday {
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    color: #9CA3B8;
    font-family: Thin;
  }
}

.vc-day {
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
  color: #202539;
  font-family: SemiBold;

  span {
    color: inherit;
    font-family: inherit;

    &:hover {
      background-color: #577FFF;
      color: #fff !important;
    }
  }

  &.on-right {
    .vc-highlights.vc-day-layer {
      .vc-day-layer.vc-day-box-right-center {
        .vc-highlight.vc-highlight-base-start {
          border-top-right-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
        }
      }

      .vc-day-layer.vc-day-box-center-center {
        .vc-highlight {
          border-top-right-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
        }
      }
    }
  }

  &.on-left {
    .vc-highlights.vc-day-layer {
      .vc-day-layer.vc-day-box-left-center {
        .vc-highlight {
          border-top-left-radius: 20px !important;
          border-bottom-left-radius: 20px !important;
        }
      }

      .vc-day-layer.vc-day-box-center-center {
        .vc-highlight {
          border-top-left-radius: 20px !important;
          border-bottom-left-radius: 20px !important;
        }
      }
    }
  }

  .vc-day-layer.vc-day-box-center-center,
  .vc-day-layer.vc-day-box-left-center,
  .vc-day-layer.vc-day-box-right-center {
    .vc-highlight {
      background: rgba(47, 87, 233, .2) !important;
    }
  }

  .vc-day-layer.vc-day-box-center-center:nth-child(2) {
    .vc-highlight {
      // height: 36px;
      // width: 36px;
      background: #577FFF !important;
      border: none !important;
      border-radius: 20px !important;
    }
  }
}
</style>
